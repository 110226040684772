<template>
  <v-row class="app-invoice-preview">
    <v-col
      cols="12"
      md="12"
    >
      <prop-comp-editable
        :data="propData"
        :zoom="zoom"
        :center="center"
        :markers="markers"
        :checkedrows="checkedrows"
        :disablefilteryear="disablefilteryear"
        :disablefiltersqrt="disablefiltersqrt"
        :disablefilterbeds="disablefilterbeds"
        :disablefilterbaths="disablefilterbaths"
        :listacomparaciones="listaComparaciones"
        @onSelectedProperty="onSelectedProperty"
        @onCheckSelectedComps="onCheckSelectedComps"
        @onCheckSoldComps="onCheckSoldComps"
        @onCheckSaleComps="onCheckSaleComps"
        @onRefreshDataComps="onRefreshDataCompsv2"
        @onChangeFilter="onChangeFilter"
        @onChangeSelectedRows="onChangeSelectedRows"
        @onDisableFilterYear="onDisableFilterYear"
        @onDisableFilterSqrt="onDisableFilterSqrt"
        @onDisableFilterBeds="onDisableFilterBeds"
        @onDisableFilterBaths="onDisableFilterBaths"
        @onWebViewDetailsClick="onWebViewDetailsClick"
      >
      </prop-comp-editable>
    </v-col>

    <!-- Right Column: Invoice Action -->
  </v-row>
</template>

<script>
import { mdiSendOutline, mdiCurrencyUsd } from '@mdi/js'
import { ref, onUnmounted } from '@vue/composition-api'
import { icon } from 'leaflet'
import axios from '@axios'

// import { DateTime } from 'luxon'
import store from '@/store'
import router from '@/router'
import PropCompEditable from './PropCompEditable.vue'
import propcompStore from './propcompStore'
import useListasAdicionales from './useListasAdicionales'

const x = 0.0
const y = 0.0

export default {
  components: {
    PropCompEditable,
  },
  mounted() {
    setTimeout(() => {
      this.onCheckSelectedComps()
      this.listpresented = 0
    }, 1000)
  },
  methods: {
    async onCheckProperty() {
      const address = `${this.propData.propData.address} ${this.propData.propData.city} ${this.propData.propData.state} ${this.propData.propData.zip}`

      const marker = {
        id: this.propData.propData.propertyId, lat: this.propData.propData.latitude, lon: this.propData.propData.longitude, address,
      }
      this.markers = []
      this.markers.push(marker)
      this.zoom = 15
      this.center = [this.propData.propData.latitude, this.propData.propData.longitude]
    },
    async onSelectedProperty(item) {
      const address = `${item.item.address} ${item.item.city} ${item.item.state} ${item.item.zip}`

      // si fue seleccionado lo agregamos, sino lo removemos si existe.
      if (item.item.isSelected) {
        const iconmarker = item.item.isSoldSale ? this.iconSale : this.iconSold
        const tipomarker = item.item.isSoldSale ? 'sale' : 'sold'
        const marker = {
          id: item.item.propertyId, lat: item.item.latitude, lon: item.item.longitude, address, icon: iconmarker, tipo: tipomarker,
        }

        this.markers.push(marker)
      } else {
        const index = this.markers.findIndex(row => row.id === item.item.propertyId)
        if (index > 0) {
          this.markers.splice(index, 1)
        }
      }
      this.onCalculateComps()
    },
    async onChangeSelectedRows(header) {
      console.log(this.listpresented)
      if (!header.checkedrows) {
        console.log('if checkedrows')
        if (this.listpresented === 0 || this.listpresented === 1) {
          console.log('if checkedrows-sold')
          // eslint-disable-next-line no-restricted-syntax
          for (const property of this.propData.propData.filterSoldProperties) {
            property.isSelected = false
            const index = this.markers.findIndex(row => row.id === property.propertyId)
            if (index > 0) {
              this.markers.splice(index, 1)
            }
          }
        }

        if (this.listpresented === 0 || this.listpresented === 2) {
          console.log('if checkedrows-sale')
          // eslint-disable-next-line no-restricted-syntax
          for (const property of this.propData.propData.filterSaleProperties) {
            property.isSelected = false
            const index = this.markers.findIndex(row => row.id === property.propertyId)
            if (index > 0) {
              this.markers.splice(index, 1)
            }
          }
        }

        if (this.listpresented === 0 || this.listpresented === 1) {
          this.propData.propData.filterListProperties = this.propData.propData.filterSoldProperties
          this.listpresented = 1
        } else {
          this.propData.propData.filterListProperties = this.propData.propData.filterSaleProperties
          this.listpresented = 2
        }

        this.onCalculateComps()
      } else {
        console.log('else checkedrows')
        if (this.listpresented === 0 || this.listpresented === 1) {
          console.log('if checkedrows-sold')
          // eslint-disable-next-line no-restricted-syntax
          for (const property of this.propData.propData.filterSoldProperties) {
            const iconmarker = property.isSoldSale ? this.iconSale : this.iconSold
            property.isSelected = true

            // Si ya existe entonces no lo agregamos
            const index = this.markers.findIndex(row => row.id === property.propertyId)
            if (index < 0) {
              const marker2 = {
                id: property.propertyId, lat: property.latitude, lon: property.longitude, address: property.address, icon: iconmarker, tipo: 'sold',
              }
              this.markers.push(marker2)
            }
          }
        }

        if (this.listpresented === 0 || this.listpresented === 2) {
          console.log('if checkedrows-sale')
          // eslint-disable-next-line no-restricted-syntax
          for (const property of this.propData.propData.filterSaleProperties) {
            const iconmarker = property.isSoldSale ? this.iconSale : this.iconSold
            property.isSelected = true

            // Si ya existe entonces no lo agregamos
            const index = this.markers.findIndex(row => row.id === property.propertyId)
            if (index < 0) {
              const marker2 = {
                id: property.propertyId, lat: property.latitude, lon: property.longitude, address: property.address, icon: iconmarker, tipo: 'sale',
              }
              this.markers.push(marker2)
            }
          }
        }

        if (this.listpresented === 0 || this.listpresented === 1) {
          this.propData.propData.filterListProperties = this.propData.propData.filterSoldProperties
          this.listpresented = 1
        } else {
          this.propData.propData.filterListProperties = this.propData.propData.filterSaleProperties
          this.listpresented = 2
        }

        this.onCalculateComps()
      }
    },
    async onWebViewDetailsClick(props) {
      const { address, city } = props.props.item

      const url = `${process.env.VUE_APP_API_URL}/api/propertieslist/leerlistapropiedades?address=${address}&city=${city}`
      const { status, data } = await axios.get(url)

      console.log(status)
      console.log(data)

      if (status === 200) {
        window.open(data[0].url, '_blank')
        window.open(data[1].url, '_blank')
      }

      // const address = props.props.item.address.replace(/\s+/g, '-')
      // const city = props.props.item.city.replace(/\s+/g, '-')
      // const { state } = props.props.item
      // const { zip } = props.props.item
      // const urlzillow = `https://www.zillow.com/homes/${address},-${city},-${state},-${zip}`
      // window.open(props.props.item.url, '_blank')
      // window.open(urlzillow, '_blank')
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    const isInvoiceSendSidebarActive = ref(false)
    const isAddPaymentSidebarActive = ref(false)

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, propcompStore)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const propData = ref({})

    // Opciones para filtro
    const {
      optionsFiltro, filterChanged, listaComparaciones,
    } = useListasAdicionales()
    const zoom = ref(14)
    const checkedrows = ref(true)
    const disablefilteryear = ref(false)
    const disablefiltersqrt = ref(false)
    const disablefilterbeds = ref(false)
    const disablefilterbaths = ref(false)
    const listpresented = ref(0)

    const center = ref([y, x])

    const markers = ref([])

    const iconSale = icon({
      iconUrl: 'images/marker-icon-green.png',
      shadowUrl: 'images/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [20, 20],
      shadowSize: [41, 41],
      shadowAnchor: [20, 20],
    })

    const iconSold = icon({
      iconUrl: 'images/marker-icon-red.png',
      shadowUrl: 'images/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [20, 20],
      shadowSize: [41, 41],
      shadowAnchor: [20, 20],
    })

    const onDisableFilterYear = () => {
      disablefilteryear.value = !disablefilteryear.value
      filterChanged.value = true
    }

    const onDisableFilterSqrt = () => {
      disablefiltersqrt.value = !disablefiltersqrt.value
      filterChanged.value = true
    }

    const onDisableFilterBeds = () => {
      disablefilterbeds.value = !disablefilterbeds.value
      filterChanged.value = true
    }

    const onDisableFilterBaths = () => {
      disablefilterbaths.value = !disablefilterbaths.value
      filterChanged.value = true
    }

    async function onCalculateComps() {
      if (propData.value.propData.filterSoldProperties.length > 0) {
        let sumDays = 0
        let countDays = 0
        let sumDaysContract = 0
        let countDaysContract = 0
        let sumPrice = 0
        let sumSqftPrice = 0
        let selectedProperties = 0

        // eslint-disable-next-line no-restricted-syntax
        for (const property of propData.value.propData.filterSoldProperties) {
          if (property.isSelected) {
            sumPrice += property.listPrice
            sumSqftPrice += property.propertySqrPrice
            sumDays += property.propertyDOM
            sumDaysContract += property.propertyDTC
            selectedProperties += 1
            if (property.propertyDOM > 0) {
              countDays += 1
            }
            if (property.propertyDTC > 0) {
              countDaysContract += 1
            }
          }
        }
        listaComparaciones.value[1].listPriceAverage = (sumPrice / selectedProperties).toFixed(2)
        listaComparaciones.value[1].sqrFtPriceAverage = (sumSqftPrice / selectedProperties).toFixed(2)
        listaComparaciones.value[1].daysOnMarketAverage = (sumDays / countDays).toFixed(0)
        listaComparaciones.value[1].daystoContractAverage = (sumDaysContract / countDaysContract).toFixed(0)
        listaComparaciones.value[1].arvPriceAverage = (listaComparaciones.value[1].sqrFtPriceAverage * propData.value.propData.propertySqrFeet).toFixed(2)
      }
      if (propData.value.propData.filterSaleProperties.length > 0) {
        let sumDays = 0
        let countDays = 0
        let sumDaysContract = 0
        let countDaysContract = 0
        let sumPrice = 0
        let sumSqftPrice = 0
        let selectedProperties = 0

        // eslint-disable-next-line no-restricted-syntax
        for (const property of propData.value.propData.filterSaleProperties) {
          if (property.isSelected) {
            sumPrice += property.listPrice
            sumSqftPrice += property.propertySqrPrice
            sumDays += property.propertyDOM
            sumDaysContract += property.propertyDTC
            selectedProperties += 1

            // const dateConv = DateTime.fromISO(property.onRedfinDate)

            // property.propertyDOM = dateConv.diffNow('days').days * -1
            // sumDays += dateConv.diffNow('days').days
            if (property.propertyDOM > 0) {
              countDays += 1
            }
            if (property.propertyDTC > 0) {
              countDaysContract += 1
            }
          }
        }
        listaComparaciones.value[0].listPriceAverage = (sumPrice / selectedProperties).toFixed(2)
        listaComparaciones.value[0].sqrFtPriceAverage = (sumSqftPrice / selectedProperties).toFixed(2)

        // listaComparaciones.value[0].daysOnMarketAverage = ((sumDays / selectedProperties) * -1).toFixed(0)
        listaComparaciones.value[0].daysOnMarketAverage = (sumDays / countDays).toFixed(0)
        listaComparaciones.value[0].daystoContractAverage = (sumDaysContract / countDaysContract).toFixed(0)
        listaComparaciones.value[0].arvPriceAverage = (listaComparaciones.value[0].sqrFtPriceAverage * propData.value.propData.propertySqrFeet).toFixed(2)
      }
    }

    const onChangeFilter = () => {
      filterChanged.value = true
    }

    const onCheckSoldComps = () => {
      listpresented.value = 1
      if (filterChanged.value || !propData.value.propData.filterSoldProperties.length) {
        optionsFiltro.value.propertyId = propData.value.propData.propertyId
        optionsFiltro.value.propAddress = propData.value.propData.address
        optionsFiltro.value.propCity = propData.value.propData.city
        optionsFiltro.value.propState = propData.value.propData.state
        optionsFiltro.value.propZipCode = propData.value.propData.zip
        optionsFiltro.value.market = propData.value.propData.market
        optionsFiltro.value.propertyType = propData.value.propData.compPropertyType
        optionsFiltro.value.status = 'Closed'
        optionsFiltro.value.latitude = propData.value.propData.latitude
        optionsFiltro.value.longitude = propData.value.propData.longitude

        const arrayFilter3 = propData.value.propData.filterWhitin
        const withinMin = arrayFilter3[0]
        const withinMax = arrayFilter3[1]
        const arrayFilter2 = propData.value.propData.filterYear
        let yearMin = arrayFilter2[0]
        let yearMax = arrayFilter2[1]
        const arrayFilter1 = propData.value.propData.filterSqrt
        let squareMin = arrayFilter1[0]
        let squareMax = arrayFilter1[1]
        if (squareMax === 3000) {
          squareMax = 5000
        }
        const arrayFilter4 = propData.value.propData.filterBeds
        let bedsMin = arrayFilter4[0]
        let bedsMax = arrayFilter4[1]
        const arrayFilter5 = propData.value.propData.filterBaths
        let bathsMin = arrayFilter5[0]
        let bathsMax = arrayFilter5[1]

        const arrayFilter6 = propData.value.propData.filterSoldwhitin
        const soldwithinMin = arrayFilter6[0]
        const soldwithinMax = arrayFilter6[1]

        if (disablefilteryear.value) {
          yearMin = 0
          yearMax = 0
        }

        if (disablefiltersqrt.value) {
          squareMin = 0
          squareMax = 0
        }

        if (disablefilterbeds.value) {
          bedsMin = 0
          bedsMax = 0
        }

        if (disablefilterbaths.value) {
          bathsMin = 0
          bathsMax = 0
        }

        optionsFiltro.value.withinMin = withinMin
        optionsFiltro.value.withinMax = withinMax
        optionsFiltro.value.yearMin = yearMin
        optionsFiltro.value.yearMax = yearMax
        optionsFiltro.value.squareMin = squareMin
        optionsFiltro.value.squareMax = squareMax
        optionsFiltro.value.bedsMin = bedsMin
        optionsFiltro.value.bedsMax = bedsMax
        optionsFiltro.value.bathsMin = bathsMin
        optionsFiltro.value.bathsMax = bathsMax
        optionsFiltro.value.soldwithinMin = soldwithinMin
        optionsFiltro.value.soldwithinMax = soldwithinMax

        // En cualquier caso removemos los marcadores.
        markers.value = markers.value.filter(item => !(item.tipo === 'sold'))

        store
          .dispatch('app-invoice/fetchCheckProperties', {
            options: optionsFiltro.value,
          })
          .then(response => {
            propData.value.propData.filterSoldProperties = response.data

            // eslint-disable-next-line no-restricted-syntax
            for (const property of propData.value.propData.filterSoldProperties) {
              property.propertySqrFeetValue = propData.value.propData.propertySqrFeet - property.propertySqrFeet
              property.propertyYearDiff = propData.value.propData.propertyYearBuilt - property.propertyYearBuilt
              property.priceVariationPercent = property.priceVariation === property.listPrice ? 0.0 : ((property.listPrice / property.priceVariation) * 100 - 100).toFixed(1)
              const iconmarker = property.isSoldSale ? iconSale : iconSold
              property.isSelected = true

              const marker2 = {
                id: property.propertyId, lat: property.latitude, lon: property.longitude, address: property.address, icon: iconmarker, tipo: 'sold',
              }

              markers.value.push(marker2)
            }

            filterChanged.value = false
            propData.value.propData.filterListProperties = propData.value.propData.filterSoldProperties
            console.log('Sold List')
            onCalculateComps()
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        propData.value.propData.filterListProperties = [...propData.value.propData.filterSoldProperties]
      }
    }

    const onCheckSaleComps = () => {
      listpresented.value = 2
      if (filterChanged.value || !propData.value.propData.filterSaleProperties.length) {
        optionsFiltro.value.propertyId = propData.value.propData.propertyId
        optionsFiltro.value.propAddress = propData.value.propData.address
        optionsFiltro.value.propCity = propData.value.propData.city
        optionsFiltro.value.propState = propData.value.propData.state
        optionsFiltro.value.propZipCode = propData.value.propData.zip
        optionsFiltro.value.market = propData.value.propData.market
        optionsFiltro.value.propertyType = propData.value.propData.compPropertyType
        optionsFiltro.value.status = 'Active'
        optionsFiltro.value.latitude = propData.value.propData.latitude
        optionsFiltro.value.longitude = propData.value.propData.longitude

        const arrayFilter3 = propData.value.propData.filterWhitin
        const withinMin = arrayFilter3[0]
        const withinMax = arrayFilter3[1]
        const arrayFilter2 = propData.value.propData.filterYear
        let yearMin = arrayFilter2[0]
        let yearMax = arrayFilter2[1]
        const arrayFilter1 = propData.value.propData.filterSqrt
        let squareMin = arrayFilter1[0]
        let squareMax = arrayFilter1[1]
        if (squareMax === 3000) {
          squareMax = 5000
        }
        const arrayFilter4 = propData.value.propData.filterBeds
        let bedsMin = arrayFilter4[0]
        let bedsMax = arrayFilter4[1]
        const arrayFilter5 = propData.value.propData.filterBaths
        let bathsMin = arrayFilter5[0]
        let bathsMax = arrayFilter5[1]
        const arrayFilter6 = propData.value.propData.filterSoldwhitin
        const soldwithinMin = arrayFilter6[0]
        const soldwithinMax = arrayFilter6[1]

        if (disablefilteryear.value) {
          yearMin = 0
          yearMax = 0
        }

        if (disablefiltersqrt.value) {
          squareMin = 0
          squareMax = 0
        }

        if (disablefilterbeds.value) {
          bedsMin = 0
          bedsMax = 0
        }

        if (disablefilterbaths.value) {
          bathsMin = 0
          bathsMax = 0
        }

        optionsFiltro.value.withinMin = withinMin
        optionsFiltro.value.withinMax = withinMax
        optionsFiltro.value.yearMin = yearMin
        optionsFiltro.value.yearMax = yearMax
        optionsFiltro.value.squareMin = squareMin
        optionsFiltro.value.squareMax = squareMax
        optionsFiltro.value.bedsMin = bedsMin
        optionsFiltro.value.bedsMax = bedsMax
        optionsFiltro.value.bathsMin = bathsMin
        optionsFiltro.value.bathsMax = bathsMax
        optionsFiltro.value.soldwithinMin = soldwithinMin
        optionsFiltro.value.soldwithinMax = soldwithinMax

        // En cualquier caso removemos los marcadores.
        markers.value = markers.value.filter(item => !(item.tipo === 'sale'))

        store
          .dispatch('app-invoice/fetchCheckProperties', {
            options: optionsFiltro.value,
          })
          .then(response => {
            propData.value.propData.filterSaleProperties = response.data

            // eslint-disable-next-line no-restricted-syntax
            for (const property of propData.value.propData.filterSaleProperties) {
              property.propertySqrFeetValue = propData.value.propData.propertySqrFeet - property.propertySqrFeet
              property.propertyYearDiff = propData.value.propData.propertyYearBuilt - property.propertyYearBuilt
              property.priceVariationPercent = property.priceVariation === property.listPrice ? 0.0 : (((property.priceVariation / property.listPrice) * 100 - 100) * -1).toFixed(1)
              const iconmarker = property.isSoldSale ? iconSale : iconSold
              property.isSelected = true

              const marker2 = {
                id: property.propertyId, lat: property.latitude, lon: property.longitude, address: property.address, icon: iconmarker, tipo: 'sale',
              }

              markers.value.push(marker2)
            }

            propData.value.propData.filterListProperties = propData.value.propData.filterSaleProperties
            filterChanged.value = false
            console.log('Sale List')
            onCalculateComps()
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        propData.value.propData.filterListProperties = [...propData.value.propData.filterSaleProperties]
      }
    }

    const onCheckSelectedComps = () => {
      listpresented.value = 0
      const filterSold = propData.value.propData.filterSoldProperties.filter(obj => obj.isSelected === true)
      const filterSale = propData.value.propData.filterSaleProperties.filter(obj => obj.isSelected === true)

      propData.value.propData.filterListProperties = [...filterSold, ...filterSale]
      propData.value.propData.filterListProperties.sort((a, b) => ((a.distance > b.distance) ? 1 : -1))
    }

    const onRefreshDataComps = () => {
      console.log('Iniciando refresh datos')
      listpresented.value = 0
      filterChanged.value = true

      if (filterChanged.value || !propData.value.propData.filterSoldProperties.length) {
        optionsFiltro.value.propertyId = propData.value.propData.propertyId
        optionsFiltro.value.propAddress = propData.value.propData.address
        optionsFiltro.value.propCity = propData.value.propData.city
        optionsFiltro.value.propState = propData.value.propData.state
        optionsFiltro.value.propZipCode = propData.value.propData.zip
        optionsFiltro.value.market = propData.value.propData.market
        optionsFiltro.value.propertyType = propData.value.propData.compPropertyType
        optionsFiltro.value.status = 'Closed'
        optionsFiltro.value.latitude = propData.value.propData.latitude
        optionsFiltro.value.longitude = propData.value.propData.longitude

        const arrayFilter3 = propData.value.propData.filterWhitin
        const withinMin = arrayFilter3[0]
        const withinMax = arrayFilter3[1]
        const arrayFilter2 = propData.value.propData.filterYear
        let yearMin = arrayFilter2[0]
        let yearMax = arrayFilter2[1]
        const arrayFilter1 = propData.value.propData.filterSqrt
        let squareMin = arrayFilter1[0]
        let squareMax = arrayFilter1[1]
        if (squareMax === 3000) {
          squareMax = 5000
        }
        const arrayFilter4 = propData.value.propData.filterBeds
        let bedsMin = arrayFilter4[0]
        let bedsMax = arrayFilter4[1]
        const arrayFilter5 = propData.value.propData.filterBaths
        let bathsMin = arrayFilter5[0]
        let bathsMax = arrayFilter5[1]

        const arrayFilter6 = propData.value.propData.filterSoldwhitin
        const soldwithinMin = arrayFilter6[0]
        const soldwithinMax = arrayFilter6[1]

        if (disablefilteryear.value) {
          yearMin = 0
          yearMax = 0
        }

        if (disablefiltersqrt.value) {
          squareMin = 0
          squareMax = 0
        }

        if (disablefilterbeds.value) {
          bedsMin = 0
          bedsMax = 0
        }

        if (disablefilterbaths.value) {
          bathsMin = 0
          bathsMax = 0
        }

        optionsFiltro.value.withinMin = withinMin
        optionsFiltro.value.withinMax = withinMax
        optionsFiltro.value.yearMin = yearMin
        optionsFiltro.value.yearMax = yearMax
        optionsFiltro.value.squareMin = squareMin
        optionsFiltro.value.squareMax = squareMax
        optionsFiltro.value.bedsMin = bedsMin
        optionsFiltro.value.bedsMax = bedsMax
        optionsFiltro.value.bathsMin = bathsMin
        optionsFiltro.value.bathsMax = bathsMax
        optionsFiltro.value.soldwithinMin = soldwithinMin
        optionsFiltro.value.soldwithinMax = soldwithinMax

        // En cualquier caso removemos los marcadores.
        markers.value = markers.value.filter(item => !(item.tipo === 'sold'))

        store
          .dispatch('app-invoice/fetchCheckProperties', {
            options: optionsFiltro.value,
          })
          .then(response => {
            propData.value.propData.filterSoldProperties = response.data

            // eslint-disable-next-line no-restricted-syntax
            for (const property of propData.value.propData.filterSoldProperties) {
              property.id = property.propertyId
              property.propertySqrFeetValue = propData.value.propData.propertySqrFeet - property.propertySqrFeet
              property.propertyYearDiff = propData.value.propData.propertyYearBuilt - property.propertyYearBuilt
              const iconmarker = property.isSoldSale ? iconSale : iconSold
              property.isSelected = true

              const marker2 = {
                id: property.propertyId, lat: property.latitude, lon: property.longitude, address: property.address, icon: iconmarker, tipo: 'sold',
              }

              markers.value.push(marker2)
            }

            console.log('Sold List')
            onCalculateComps()
          })
          .catch(error => {
            console.log(error)
          })
      }

      filterChanged.value = true
      if (filterChanged.value || !propData.value.propData.filterSaleProperties.length) {
        optionsFiltro.value.propertyId = propData.value.propData.propertyId
        optionsFiltro.value.propAddress = propData.value.propData.address
        optionsFiltro.value.propCity = propData.value.propData.city
        optionsFiltro.value.propState = propData.value.propData.state
        optionsFiltro.value.propZipCode = propData.value.propData.zip
        optionsFiltro.value.market = propData.value.propData.market
        optionsFiltro.value.propertyType = propData.value.propData.compPropertyType
        optionsFiltro.value.status = 'Active'
        optionsFiltro.value.latitude = propData.value.propData.latitude
        optionsFiltro.value.longitude = propData.value.propData.longitude

        const arrayFilter3 = propData.value.propData.filterWhitin
        const withinMin = arrayFilter3[0]
        const withinMax = arrayFilter3[1]
        const arrayFilter2 = propData.value.propData.filterYear
        let yearMin = arrayFilter2[0]
        let yearMax = arrayFilter2[1]
        const arrayFilter1 = propData.value.propData.filterSqrt
        let squareMin = arrayFilter1[0]
        let squareMax = arrayFilter1[1]
        if (squareMax === 3000) {
          squareMax = 5000
        }
        const arrayFilter4 = propData.value.propData.filterBeds
        let bedsMin = arrayFilter4[0]
        let bedsMax = arrayFilter4[1]
        const arrayFilter5 = propData.value.propData.filterBaths
        let bathsMin = arrayFilter5[0]
        let bathsMax = arrayFilter5[1]
        const arrayFilter6 = propData.value.propData.filterSoldwhitin
        const soldwithinMin = arrayFilter6[0]
        const soldwithinMax = arrayFilter6[1]

        if (disablefilteryear.value) {
          yearMin = 0
          yearMax = 0
        }

        if (disablefiltersqrt.value) {
          squareMin = 0
          squareMax = 0
        }

        if (disablefilterbeds.value) {
          bedsMin = 0
          bedsMax = 0
        }

        if (disablefilterbaths.value) {
          bathsMin = 0
          bathsMax = 0
        }

        optionsFiltro.value.withinMin = withinMin
        optionsFiltro.value.withinMax = withinMax
        optionsFiltro.value.yearMin = yearMin
        optionsFiltro.value.yearMax = yearMax
        optionsFiltro.value.squareMin = squareMin
        optionsFiltro.value.squareMax = squareMax
        optionsFiltro.value.bedsMin = bedsMin
        optionsFiltro.value.bedsMax = bedsMax
        optionsFiltro.value.bathsMin = bathsMin
        optionsFiltro.value.bathsMax = bathsMax
        optionsFiltro.value.soldwithinMin = soldwithinMin
        optionsFiltro.value.soldwithinMax = soldwithinMax

        // En cualquier caso removemos los marcadores.
        markers.value = markers.value.filter(item => !(item.tipo === 'sale'))

        store
          .dispatch('app-invoice/fetchCheckProperties', {
            options: optionsFiltro.value,
          })
          .then(response => {
            propData.value.propData.filterSaleProperties = response.data

            // eslint-disable-next-line no-restricted-syntax
            for (const property of propData.value.propData.filterSaleProperties) {
              property.id = property.propertyId
              property.propertySqrFeetValue = propData.value.propData.propertySqrFeet - property.propertySqrFeet
              property.propertyYearDiff = propData.value.propData.propertyYearBuilt - property.propertyYearBuilt
              const iconmarker = property.isSoldSale ? iconSale : iconSold
              property.isSelected = true

              const marker2 = {
                id: property.propertyId, lat: property.latitude, lon: property.longitude, address: property.address, icon: iconmarker, tipo: 'sale',
              }

              markers.value.push(marker2)
            }

            console.log('Sale List')
            onCalculateComps()
          })
          .then(() => {
            filterChanged.value = false
            console.log('Refresh Data')

            const filterSold = propData.value.propData.filterSoldProperties.filter(obj => obj.isSelected === true)
            const filterSale = propData.value.propData.filterSaleProperties.filter(obj => obj.isSelected === true)

            propData.value.propData.filterListProperties = [...filterSold, ...filterSale]
            propData.value.propData.filterListProperties.sort((a, b) => ((a.distance > b.distance) ? 1 : -1))
            onCalculateComps()
          })
          .catch(error => {
            console.log(error)
          })
      }

      console.log('Terminando refresh datos')
    }

    const onRefreshDataCompsv2 = async () => {
      listpresented.value = 0
      filterChanged.value = true

      if (filterChanged.value || !propData.value.propData.filterSoldProperties.length) {
        optionsFiltro.value.propertyId = propData.value.propData.propertyId
        optionsFiltro.value.propAddress = propData.value.propData.address
        optionsFiltro.value.propCity = propData.value.propData.city
        optionsFiltro.value.propState = propData.value.propData.state
        optionsFiltro.value.propZipCode = propData.value.propData.zip
        optionsFiltro.value.market = propData.value.propData.market
        optionsFiltro.value.propertyType = propData.value.propData.compPropertyType
        optionsFiltro.value.status = 'Closed'
        optionsFiltro.value.latitude = propData.value.propData.latitude
        optionsFiltro.value.longitude = propData.value.propData.longitude

        const arrayFilter3 = propData.value.propData.filterWhitin
        const withinMin = arrayFilter3[0]
        const withinMax = arrayFilter3[1]
        const arrayFilter2 = propData.value.propData.filterYear
        let yearMin = arrayFilter2[0]
        let yearMax = arrayFilter2[1]
        const arrayFilter1 = propData.value.propData.filterSqrt
        let squareMin = arrayFilter1[0]
        let squareMax = arrayFilter1[1]
        if (squareMax === 3000) {
          squareMax = 5000
        }
        const arrayFilter4 = propData.value.propData.filterBeds
        let bedsMin = arrayFilter4[0]
        let bedsMax = arrayFilter4[1]
        const arrayFilter5 = propData.value.propData.filterBaths
        let bathsMin = arrayFilter5[0]
        let bathsMax = arrayFilter5[1]

        const arrayFilter6 = propData.value.propData.filterSoldwhitin
        const soldwithinMin = arrayFilter6[0]
        const soldwithinMax = arrayFilter6[1]

        if (disablefilteryear.value) {
          yearMin = 0
          yearMax = 0
        }

        if (disablefiltersqrt.value) {
          squareMin = 0
          squareMax = 0
        }

        if (disablefilterbeds.value) {
          bedsMin = 0
          bedsMax = 0
        }

        if (disablefilterbaths.value) {
          bathsMin = 0
          bathsMax = 0
        }

        optionsFiltro.value.withinMin = withinMin
        optionsFiltro.value.withinMax = withinMax
        optionsFiltro.value.yearMin = yearMin
        optionsFiltro.value.yearMax = yearMax
        optionsFiltro.value.squareMin = squareMin
        optionsFiltro.value.squareMax = squareMax
        optionsFiltro.value.bedsMin = bedsMin
        optionsFiltro.value.bedsMax = bedsMax
        optionsFiltro.value.bathsMin = bathsMin
        optionsFiltro.value.bathsMax = bathsMax
        optionsFiltro.value.soldwithinMin = soldwithinMin
        optionsFiltro.value.soldwithinMax = soldwithinMax

        // En cualquier caso removemos los marcadores.
        markers.value = markers.value.filter(item => !(item.tipo === 'sold'))

        const url = `${process.env.VUE_APP_API_URL}/api/propertiescatalog/leerlistageo`

        const { status, data } = await axios.get(url, {
          params: {
            options: optionsFiltro.value,
          },
        })

        if (status === 200) {
          propData.value.propData.filterSoldProperties = data
          // eslint-disable-next-line no-restricted-syntax
          for (const property of propData.value.propData.filterSoldProperties) {
            property.id = property.propertyId
            property.propertySqrFeetValue = propData.value.propData.propertySqrFeet - property.propertySqrFeet
            property.propertyYearDiff = propData.value.propData.propertyYearBuilt - property.propertyYearBuilt
            property.priceVariationPercent = property.priceVariation === property.listPrice ? 0.0 : ((property.listPrice / property.priceVariation) * 100 - 100).toFixed(1)
            const iconmarker = property.isSoldSale ? iconSale : iconSold
            property.isSelected = true

            const marker2 = {
              id: property.propertyId, lat: property.latitude, lon: property.longitude, address: property.address, icon: iconmarker, tipo: 'sold',
            }

            markers.value.push(marker2)
          }

          console.log('Sold List')
          onCalculateComps()
        }
      }

      filterChanged.value = true
      if (filterChanged.value || !propData.value.propData.filterSaleProperties.length) {
        optionsFiltro.value.propertyId = propData.value.propData.propertyId
        optionsFiltro.value.propAddress = propData.value.propData.address
        optionsFiltro.value.propCity = propData.value.propData.city
        optionsFiltro.value.propState = propData.value.propData.state
        optionsFiltro.value.propZipCode = propData.value.propData.zip
        optionsFiltro.value.market = propData.value.propData.market
        optionsFiltro.value.propertyType = propData.value.propData.compPropertyType
        optionsFiltro.value.status = 'Active'
        optionsFiltro.value.latitude = propData.value.propData.latitude
        optionsFiltro.value.longitude = propData.value.propData.longitude

        const arrayFilter3 = propData.value.propData.filterWhitin
        const withinMin = arrayFilter3[0]
        const withinMax = arrayFilter3[1]
        const arrayFilter2 = propData.value.propData.filterYear
        let yearMin = arrayFilter2[0]
        let yearMax = arrayFilter2[1]
        const arrayFilter1 = propData.value.propData.filterSqrt
        let squareMin = arrayFilter1[0]
        let squareMax = arrayFilter1[1]
        if (squareMax === 3000) {
          squareMax = 5000
        }
        const arrayFilter4 = propData.value.propData.filterBeds
        let bedsMin = arrayFilter4[0]
        let bedsMax = arrayFilter4[1]
        const arrayFilter5 = propData.value.propData.filterBaths
        let bathsMin = arrayFilter5[0]
        let bathsMax = arrayFilter5[1]
        const arrayFilter6 = propData.value.propData.filterSoldwhitin
        const soldwithinMin = arrayFilter6[0]
        const soldwithinMax = arrayFilter6[1]

        if (disablefilteryear.value) {
          yearMin = 0
          yearMax = 0
        }

        if (disablefiltersqrt.value) {
          squareMin = 0
          squareMax = 0
        }

        if (disablefilterbeds.value) {
          bedsMin = 0
          bedsMax = 0
        }

        if (disablefilterbaths.value) {
          bathsMin = 0
          bathsMax = 0
        }

        optionsFiltro.value.withinMin = withinMin
        optionsFiltro.value.withinMax = withinMax
        optionsFiltro.value.yearMin = yearMin
        optionsFiltro.value.yearMax = yearMax
        optionsFiltro.value.squareMin = squareMin
        optionsFiltro.value.squareMax = squareMax
        optionsFiltro.value.bedsMin = bedsMin
        optionsFiltro.value.bedsMax = bedsMax
        optionsFiltro.value.bathsMin = bathsMin
        optionsFiltro.value.bathsMax = bathsMax
        optionsFiltro.value.soldwithinMin = soldwithinMin
        optionsFiltro.value.soldwithinMax = soldwithinMax

        // En cualquier caso removemos los marcadores.
        markers.value = markers.value.filter(item => !(item.tipo === 'sale'))

        const url = `${process.env.VUE_APP_API_URL}/api/propertiescatalog/leerlistageo`

        const { status, data } = await axios.get(url, {
          params: {
            options: optionsFiltro.value,
          },
        })

        if (status === 200) {
          propData.value.propData.filterSaleProperties = data

          // eslint-disable-next-line no-restricted-syntax
          for (const property of propData.value.propData.filterSaleProperties) {
            property.id = property.propertyId
            property.propertySqrFeetValue = propData.value.propData.propertySqrFeet - property.propertySqrFeet
            property.propertyYearDiff = propData.value.propData.propertyYearBuilt - property.propertyYearBuilt
            property.priceVariationPercent = property.priceVariation === property.listPrice ? 0.0 : (((property.priceVariation / property.listPrice) * 100 - 100) * -1).toFixed(1)
            const iconmarker = property.isSoldSale ? iconSale : iconSold
            property.isSelected = true

            const marker2 = {
              id: property.propertyId, lat: property.latitude, lon: property.longitude, address: property.address, icon: iconmarker, tipo: 'sale',
            }

            markers.value.push(marker2)
          }

          console.log('Sale List')
          onCalculateComps()
        }
      }

      filterChanged.value = false
      console.log('Refresh Data')

      const filterSold = propData.value.propData.filterSoldProperties.filter(obj => obj.isSelected === true)
      const filterSale = propData.value.propData.filterSaleProperties.filter(obj => obj.isSelected === true)

      propData.value.propData.filterListProperties = [...filterSold, ...filterSale]
      propData.value.propData.filterListProperties.sort((a, b) => ((a.distance > b.distance) ? 1 : -1))
      onCalculateComps()
    }

    store
      .dispatch('app-invoice/fetchPropComparison', { id: router.currentRoute.params.id })
      .then(response => {
        propData.value = {
          propData: response.data,
        }

        // generamos los rangos seleccionados por el usuario
        propData.value.propData.filterYear = [propData.value.propData.filterYearMin, propData.value.propData.filterYearMax]
        propData.value.propData.filterSqrt = [propData.value.propData.filterSqrtMin, propData.value.propData.filterSqrtMax]
        propData.value.propData.filterWhitin = [propData.value.propData.filterWhitinMin, propData.value.propData.filterWhitinMax]
        propData.value.propData.filterBeds = [propData.value.propData.filterBedsMin, propData.value.propData.filterBedsMax]
        propData.value.propData.filterBaths = [propData.value.propData.filterBathsMin, propData.value.propData.filterBathsMax]
        propData.value.propData.filterSoldwhitin = [propData.value.propData.filterSoldwhitinMin, propData.value.propData.filterSoldwhitinMax]
        center.value = [propData.value.propData.latitude, propData.value.propData.longitude]
        zoom.value = 14

        // generamos el marcador inicial
        const address = `${propData.value.propData.address} ${propData.value.propData.city} ${propData.value.propData.state} ${propData.value.propData.zip}`
        const marker = {
          id: propData.value.propData.propertyId, lat: propData.value.propData.latitude, lon: propData.value.propData.longitude, address,
        }
        markers.value.push(marker)

        // asignamos los valores por min-max por default a los filtros
        propData.value.propData.filterYearMin = 1950
        propData.value.propData.filterYearMax = 2022
        propData.value.propData.filterWhitinMin = 0.0
        propData.value.propData.filterWhitinMax = 1.0
        propData.value.propData.filterSqrtMin = 0
        propData.value.propData.filterSqrtMax = 3000
        propData.value.propData.filterBedsMin = 0
        propData.value.propData.filterBedsMax = 5
        propData.value.propData.filterBathsMin = 0
        propData.value.propData.filterBathsMax = 5
        propData.value.propData.filterSoldwhitinMin = 0
        propData.value.propData.filterSoldwhitinMax = 12

        // asignamos los marcadores de las propiedades seleccionadas.
        propData.value.propData.filterListProperties.forEach(element => {
          const iconmarker = element.isSoldSale ? iconSale : iconSold
          const tipomarker = element.isSoldSale ? 'sale' : 'sold'

          const marker2 = {
            id: element.propertyId, lat: element.latitude, lon: element.longitude, address: element.address, icon: iconmarker, tipo: tipomarker,
          }

          markers.value.push(marker2)
        })

        listpresented.value = 0

        return response.data
      })
      .then(() => {
        console.log('2do then')
        if (propData.value.propData.filterSoldProperties.length === 0 && propData.value.propData.filterSaleProperties.length === 0) {
          filterChanged.value = true
          onCheckSoldComps()
          filterChanged.value = true
          onCheckSaleComps()
        } else {
          // eslint-disable-next-line no-restricted-syntax
          for (const property of propData.value.propData.filterSaleProperties) {
            property.propertySqrFeetValue = propData.value.propData.propertySqrFeet - property.propertySqrFeet
            property.propertyYearDiff = propData.value.propData.propertyYearBuilt - property.propertyYearBuilt

            // property.priceVariationPercent = property.priceVariation === property.listPrice ? 0.0 : (((property.priceVariation / property.listPrice) * 100 - 100) * -1).toFixed(1)
          }
          // eslint-disable-next-line no-restricted-syntax
          for (const property of propData.value.propData.filterSoldProperties) {
            property.propertySqrFeetValue = propData.value.propData.propertySqrFeet - property.propertySqrFeet
            property.propertyYearDiff = propData.value.propData.propertyYearBuilt - property.propertyYearBuilt

            // property.priceVariationPercent = property.priceVariation === property.listPrice ? 0.0 : ((property.listPrice / property.priceVariation) * 100 - 100).toFixed(1)
          }
          // eslint-disable-next-line no-restricted-syntax
          for (const property of propData.value.propData.filterListProperties) {
            property.propertySqrFeetValue = propData.value.propData.propertySqrFeet - property.propertySqrFeet
            property.propertyYearDiff = propData.value.propData.propertyYearBuilt - property.propertyYearBuilt
          }
        }
      })
      .then(() => {
        console.log('3er then')
        onCalculateComps()

        // 20231211
        console.log('Refresh List')
        onRefreshDataCompsv2()
      })
      .catch(error => {
        console.log(error)
        propData.value = undefined
      })

    // onCheckSelectedComps()

    return {
      propData,
      zoom,
      checkedrows,
      disablefilteryear,
      disablefiltersqrt,
      disablefilterbeds,
      disablefilterbaths,
      center,
      markers,
      onCheckSelectedComps,
      onCheckSoldComps,
      onCheckSaleComps,
      onChangeFilter,
      onDisableFilterYear,
      onDisableFilterSqrt,
      onDisableFilterBeds,
      onDisableFilterBaths,
      onCalculateComps,
      onRefreshDataComps,
      onRefreshDataCompsv2,
      iconSale,
      iconSold,
      listaComparaciones,
      listpresented,

      // drawer
      isInvoiceSendSidebarActive,
      isAddPaymentSidebarActive,

      // Icons
      icons: {
        mdiSendOutline,
        mdiCurrencyUsd,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

</style>
